import request from "@/utils/request";

/**
 * 获取系统信息
 */
export function apiGetSystem() {
  return request.get("/setting/getSystem");
}

/**
 * 设置系统信息
 * @param params
 */
export function apiSetSystem(params) {
  return request.post("/setting/setSystem", { ...params });
}

/**
 * 获取邮箱信息
 */
export function apiGetMailer() {
  return request.get("/setting/getMailer");
}

/**
 * 设置邮箱信息
 * @param params
 */
export function apiSetMailer(params) {
  return request.post("/setting/setMailer", { ...params });
}

export function apiTryMailer(params) {
  return request.post("/setting/tryMailer", { ...params });
}
