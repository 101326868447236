<template>
  <div class="app-container pb-50px">
    <el-card v-loading="loading" shadow="never">
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        size="small"
        label-width="90px"
      >
        <el-form-item label="站点名称" prop="site_name">
          <el-input
            v-model="form.site_name"
            style="width: 480px"
            placeholder="请输入站点名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="过滤关键字" prop="mask_keyword">
          <el-input
            type="textarea"
            rows="5"
            style="width: 600px"
            v-model="form.mask_keyword"
            placeholder="请输入过滤关键字"
          ></el-input>
          <div class="form-tips">过滤关键字用英文逗号分隔，如：关键词1,关键词2</div>
        </el-form-item>
      </el-form>
    </el-card>
    <footer-wrap>
      <el-button type="primary" size="small" @click="onSubmit">
        保 存
      </el-button>
    </footer-wrap>
  </div>
</template>

<script>
import FooterWrap from "@/components/footer-wrap/index.vue";

import { apiGetSystem, apiSetSystem } from "@/api/setting";

export default {
  components: {
    FooterWrap,
  },
  data() {
    return {
      loading: false,
      form: {
        site_name: "",
        mask_keyword: "",
      },
      rules: {},
    };
  },
  created() {
    this.getSystem();
  },
  methods: {
    getSystem() {
      this.loading = true;
      apiGetSystem().then((resp) => {
        this.form.site_name = resp.site_name;
        this.form.mask_keyword = resp.mask_keyword;
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        apiSetSystem({
          site_name: this.form.site_name,
          mask_keyword: this.form.mask_keyword,
        }).then(() => {
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          this.getSystem();
        });
      });
    },
  },
};
</script>
